var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "router-link",
        { attrs: { to: "/setting/edit/" + _vm.data.id } },
        [
          _c("el-button", {
            attrs: { type: "primary", size: "small", icon: "el-icon-edit" }
          })
        ],
        1
      ),
      _c(
        "el-descriptions",
        {
          attrs: {
            title: "Setting Details",
            direction: "vertical",
            colon: false,
            "label-style": { "font-weight": "bold" }
          }
        },
        [
          _c(
            "el-descriptions-item",
            { attrs: { label: "Bid Start Duration" } },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.data.bidStartTime) +
                  " - " +
                  _vm._s(_vm.data.bidEndTime) +
                  " "
              )
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "Bid Approval Duration" } },
            [_vm._v(" " + _vm._s(_vm.data.bidApprovalHours) + " mins ")]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "Bid Expiry Duration" } },
            [_vm._v(" " + _vm._s(_vm.data.bidExpiryHours) + " mins ")]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "Booking Amount Threshold" } },
            [_c("inr", { attrs: { number: _vm.data.bookingAmountThreshold } })],
            1
          ),
          _c("el-descriptions-item", { attrs: { label: "Transaction %" } }, [
            _vm._v(" " + _vm._s(_vm.data.transactionPercent) + " ")
          ]),
          _c("el-descriptions-item", { attrs: { label: "Bank Details" } }, [
            _vm._v(" " + _vm._s(_vm.data.bankInformation) + " ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }